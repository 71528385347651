<template>
  <div class="hp-header-logo d-flex align-items-center">
    <b-link to="/" class="position-relative d-flex">
      <img v-if="this.$store.state.themeConfig.direction == 'rtl' && this.$store.state.themeConfig.theme == 'light'"
        class="hp-logo" v-bind:src="require('@/assets/img/logo/logo.svg')" alt="Allnube" title="Allnube"
        style="width: 150px;" />
      <img v-if="this.$store.state.themeConfig.direction == 'rtl' && this.$store.state.themeConfig.theme == 'dark'"
        class="hp-logo" v-bind:src="require('@/assets/img/logo/logo-dark.svg')" alt="Allnube" title="Allnube"
        style="width: 150px;" />
      <img
        v-else-if="this.$store.state.themeConfig.direction == 'ltr' && this.$store.state.themeConfig.theme == 'light'"
        class="hp-logo" v-bind:src="require('@/assets/img/logo/logo.svg')" alt="Allnube" title="Allnube"
        style="width: 150px;" />
      <img v-else-if="this.$store.state.themeConfig.direction == 'ltr' && this.$store.state.themeConfig.theme == 'dark'"
        class="hp-logo" v-bind:src="require('@/assets/img/logo/logo-dark.svg')" alt="Allnube" title="Allnube"
        style="width: 150px;" />
    </b-link>

    <a href="#" target="_blank" class="hp-caption font-weight-normal hp-text-color-primary-1">
      v.{{ this.$store.state.themeConfig.version }}
    </a>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
}
</script>
