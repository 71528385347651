import dashboards from "./dashboards";
// import applications from "./applications"
// import pages from "./pages"
import userInterface from "./user-interface";

export default [
  dashboards,
  // applications,
  // pages,
  userInterface,
];
